import './index.scss';
import myContext from '../../context';
// import { ethers } from 'ethers';
import { useEffect, useState, useContext, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import { splitArr } from '../../hooks/splitArr';
import { Carousel } from 'react-responsive-carousel';
import { Input, InputNumber, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import copy from 'copy-to-clipboard';

export default function Index() {
  const navigate = useNavigate();
  const goManage = () => navigate('/manage'); // 页面跳转
  const { locale, WalletProviders, Bl, UserInfo, Gameinfo, ClaimableIncome, GetGameInfo, joinGame, BindRef, WithdrawalOfRefIncome, WithdrawalOfStaticIncome, WithdrawalOfPascalIncome, WithdrawalOfTeamIncome, arr, IsOwner, RefUrl, bindSuperiorView, myRefUrl } = useContext(myContext);
  const [BetV, setBetV] = useState();
  const [BindRefAddr, setBindRefAddr] = useState();

  const initialLoading = {
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    loading5: false
  };

  const loadingReducer = (state, action) => {
    const changeState = {
      [`loading${action.type}`]: action.value
    };
    return { ...state, ...changeState };
  };

  const [state, dispatch] = useReducer(loadingReducer, initialLoading);

  const withdraw = async (type) => {
    dispatch({ type, value: true });
    if (type == 1) {
      await WithdrawalOfRefIncome();
    } else if (type == 2) {
      await WithdrawalOfStaticIncome();
    } else if (type == 3) {
      await WithdrawalOfPascalIncome();
    } else if (type == 4) {
      await WithdrawalOfTeamIncome();
    } else if (type == 5) {
      await joinGame(BetV);
    }

    dispatch({ type, value: false });
  };

  useEffect(() => {
    if (!WalletProviders) {
      return;
    }
  }, [WalletProviders, UserInfo]);

  let Manage = () => {
    if (IsOwner == 'true') {
      //console.log(00);
      return (
        <div>
          <Button className="w-100 mb-3" loading={false} danger onClick={goManage}>
            进入管理页面
          </Button>
        </div>
      );
    }
  };
  const copyCot = (cot) => {
    copy(cot);
    locale == 'zh' ? message.success('复制成功') : message.success('copy succeeded');
  };
  const Abbreviation = (Accounts) => Accounts.substring(0, 7) + '...' + Accounts.substring(37, 42).toString();
  const zeroAddr = '0x0000000000000000000000000000000000000000';

  const [obj, setObj] = useState({
    aaaaaaaaaaa: 1,
    bbbbbbbbbbbbbb: 2,
    cccdcccdcscdc: 3,
    dudgghdfdgfdgag: 4,
    efgerterhtjertrt: 5,
    erewtvffsdfff: 6
  });
  const [level, setLevel] = useState(5);

  return (
    <div className="go-page">
      {/* <Manage></Manage> */}
      <div className="text-zero">
        <img className="w-100" src={require('../../assets/img/banner3.png')} alt="" />
      </div>
      <div className="px-3 mt-4">
        <div className="d-flex align-items-center">
          <div className="text-zero">
            <img className="icon" src={require('../../assets/img/logo.png')} alt="" />
          </div>
          <div className="color-warning text-plus ml-2">SHIB</div>
        </div>
        <div className="mt-2-5">
          <div className="flex-item">
            <div className="input-wrapper flex-content flex-item align-items-center">
              <div className="flex-label color-warning text-des">
                <FormattedMessage id="superiorAddress" />
              </div>
              {/* <Input  value={UserInfo[5] == ''?'':Abbreviation(UserInfo[5])} className="flex-content text-right no-border" /> */}
              <Input value={UserInfo[5] == '' ? '' : UserInfo[5]} className="flex-content text-right no-border" />
            </div>
            <Button
              danger
              className="flex-label warning ml-2"
              onClick={async () => {
                copyCot(UserInfo[5]);
              }}
            >
              <FormattedMessage id="copy" />
            </Button>
          </div>
          <div className="flex-item mt-2">
            <div className="input-wrapper flex-content flex-item align-items-center">
              <div className="flex-label color-warning text-des">
                <FormattedMessage id="bindingParent" />
              </div>
              <Input
                className="flex-content text-right no-border"
                value={UserInfo[5] != '0x0000000000000000000000000000000000000000' ? UserInfo[5] : bindSuperiorView}
                onChange={async (event) => {
                  setBindRefAddr(event.target.value);
                }}
              />
            </div>
            <Button
              danger
              className="flex-label warning ml-2"
              disabled={UserInfo[5] == '0x0000000000000000000000000000000000000000' ? false : true}
              onClick={async () => {
                //console.log('bindSuperiorView',bindSuperiorView,BindRefAddr);
                if (bindSuperiorView) {
                  await BindRef(bindSuperiorView);
                } else {
                  await BindRef(BindRefAddr);
                }
              }}
            >
              <FormattedMessage id="bind" />
            </Button>
          </div>
          <div className="flex-item mt-2">
            <div className="input-wrapper flex-content flex-item align-items-center">
              <div className="flex-label color-warning text-des">
                <FormattedMessage id="myShareLink" />
              </div>
              <Input className="flex-content text-right no-border" value={myRefUrl} />
            </div>
            <Button
              danger
              className="flex-label warning ml-2"
              onClick={async () => {
                if (myRefUrl) {
                  copyCot(myRefUrl);
                }
              }}
            >
              <FormattedMessage id="copy" />
            </Button>
          </div>
          <div className="flex-item mt-2">
            <div className="input-wrapper flex-content flex-item align-items-center">
              <div className="flex-label color-warning text-des">
                <FormattedMessage id="myInvitationCode" />
              </div>
              <Input className="flex-content text-right no-border" value={myRefUrl ? myRefUrl.substr(-42, 42) : ''} />
            </div>
            <Button
              danger
              className="flex-label warning ml-2"
              onClick={async () => {
                if (myRefUrl) {
                  copyCot(myRefUrl.substr(-42, 42));
                }
              }}
            >
              <FormattedMessage id="copy" />
            </Button>
          </div>
        </div>
        <div className="mt-5 px-5">
          <div className="text-zero">{locale == 'zh' ? <img className="w-100" src={require('../../assets/img/btn-bg.png')} alt="" /> : <img className="w-100" src={require('../../assets/img/btn-bg-e.png')} alt="" />}</div>
          <div className="my-4">
            <div className="input-wrapper2 flex-item align-items-center">
              <div className="flex-label color-lights text-des">
                <FormattedMessage id="dailyReturnRate" />
              </div>
              <Input value={Gameinfo[1] ? Gameinfo[1] / 10 + '%' : '- %'} readOnly className="flex-content text-right no-border ml-2" />
            </div>
            <div className="input-wrapper2 mt-2 flex-item align-items-center">
              <div className="flex-label color-lights text-des">
                <FormattedMessage id="walletBalance" />
              </div>
              <Input value={Bl[1] ? Bl[1] : '0.0'} readOnly className="flex-content text-right no-border ml-2" />
            </div>
            <div className="input-wrapper2 mt-2 flex-item align-items-center">
              <div className="flex-label color-lights text-des">
                <FormattedMessage id="numOfParticipants" />
              </div>
              <InputNumber
                defaultValue={0}
                value={BetV}
                // value={!BetV ? '' : BetV.toString()}
                precision={0}
                className="flex-content mx-2"
                onChange={(value) => {
                  console.log(value);
                  setBetV(value);
                  //setBindRefAddr(event.target.value);
                }}
              />
              <Button
                danger
                className="warning linear linear-reverse mini"
                onClick={async () => {
                  setBetV(Bl[1]);
                  //console.log(BetV);
                }}
              >
                MAX
              </Button>
            </div>
          </div>
          <div>
            <div className="text-min text-center mb-2-5">
              <FormattedMessage id="participateRow" />
            </div>
            <Button
              danger
              loading={state.loading5}
              className="warning linear w-100"
              onClick={async () => {
                await withdraw(5);
              }}
            >
              <FormattedMessage id="confirm" />
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="text-zero">
          <img className="w-100" src={require('../../assets/img/banner4.png')} alt="" />
        </div>
      </div>
      <div className="mt-5-5 px-5">
        <div className="mb-4 text-des">
          <div className="flex-item">
            <div className="flex-content">
              <FormattedMessage id="boneBalance" />
            </div>
            <div className="flex-label ml-2">{Bl[0] ? Bl[0] : '0.0'}</div>
          </div>
          <div className="flex-item mt-3">
            <div className="flex-content">
              <FormattedMessage id="amplificationIncome" />
            </div>
            <div className="flex-label ml-2">{UserInfo[6] ? UserInfo[6] : '0.0'}</div>
          </div>
          <div className="flex-item mt-3">
            <div className="flex-content">
              <FormattedMessage id="acceleratedRelease" />
            </div>
            <div className="flex-label ml-2">{UserInfo[7] ? UserInfo[7] : '0.0'}</div>
            {/* <div className="flex-label ml-2">{(UserInfo[6] - UserInfo[7]?UserInfo[6] - UserInfo[7]:'0.0')}</div> */}
          </div>
          <div className="flex-item mt-3">
            <div className="flex-content">
              <FormattedMessage id="toBeReleased" />
            </div>
            {/* <div className="flex-label ml-2">{UserInfo[7] ? UserInfo[7] : '0.0'}</div> */}
            <div className="flex-label ml-2">{UserInfo[6] - UserInfo[7] ? UserInfo[6] - UserInfo[7] : '0.0'}</div>
          </div>
        </div>
        <div>
          <div className="increase-box flex-item align-items-center text-mini">
            <div className="flex-label d-flex align-items-center">
              <div className="text-zero mr-2">
                <img className="icon" src={require('../../assets/img/icon2.png')} alt="" />
              </div>
              <div>BONE/USDT</div>
            </div>
            <div className="flex-content pl-4 text-right">${Bl[3] ? Bl[3] : '0.0'}</div>
            {/* <div className="tag success">+5.51%</div> */}
          </div>
          <div className="increase-box flex-item align-items-center text-mini mt-2">
            <div className="flex-label d-flex align-items-center">
              <div className="text-zero mr-2">
                <img className="icon" src={require('../../assets/img/logo.png')} alt="" />
              </div>
              <div>SHIB/USDT</div>
            </div>
            <div className="flex-content pl-4 text-right">${Bl[2] ? Bl[2] : '0.0'}</div>
            {/* <div className="tag danger">-5.51%</div> */}
          </div>
        </div>
      </div>
      <div className="my-5-5 px-3">
        <div className="px-3">
          <div className="px-5 text-zero">{locale == 'zh' ? <img className="w-100" src={require('../../assets/img/btn-bg2.png')} alt="" /> : <img className="w-100" src={require('../../assets/img/btn-bg2-e.png')} alt="" />}</div>
          <div className="px-3 pt-5 pb-3">
            <div className="flex-item align-items-center">
              <div className="flex-content">
                <FormattedMessage id="teamLevel" />
              </div>
              <div className="flex-label d-flex align-items-center">
                {UserInfo[0] !== '0'
                  ? new Array(Number(UserInfo[0])).fill(1).map((item, index) => {
                      //console.log(UserInfo[0],'---', typeof(UserInfo[0]));
                      return (
                        <div key={index} className="text-zero">
                          <img className="star" src={require('../../assets/img/star.png')} alt="" />
                        </div>
                      );
                    })
                  : '0'}
              </div>
            </div>
            <div className="flex-item align-items-center mt-2">
              <div className="flex-content">
                <FormattedMessage id="isNode" />
              </div>
              <div className="flex-label">
                {UserInfo[1] == 'true' ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}

                {/* <FormattedMessage id="no" /> */}
              </div>
            </div>
            <div className="flex-item align-items-center mt-2">
              <div className="flex-content">
                <FormattedMessage id="sharePersonNum" />
              </div>
              <div className="flex-label">{!UserInfo[2] ? '0' : UserInfo[2]}</div>
            </div>
            {/* <div className="flex-item align-items-center mt-2">
              <div className="flex-content">
                <FormattedMessage id="sharePerformance" />
              </div>
              <div className="flex-label">{!UserInfo[4]?'0':UserInfo[4]}</div>
            </div> */}
            <div className="flex-item align-items-center mt-2">
              <div className="flex-content">
                <FormattedMessage id="teamPerformance" />
              </div>
              <div className="flex-label">{!UserInfo[4] ? '0' : UserInfo[4]}</div>
            </div>
          </div>
          <div className="px-3 text-lg color-warning">
            <FormattedMessage id="directAddress" />
          </div>
        </div>
        <div className="mt-3">
          <Carousel autoPlay infiniteLoop interval="100000" showIndicators={false} showStatus={false} showThumbs={false}>
            {splitArr(Object.entries(UserInfo[3]), 3).map((item, index) => {
              return (
                <div key={index} className="px-0-5">
                  {item.map((subItem, subIndex) => {
                    return (
                      <div key={subIndex} className={`color-light flex-item ${subIndex ? 'mt-2' : ''}`}>
                        <div className="flex-content text-des text-left">{subItem[0]}</div>
                        <div className="flex-label color-white">{subItem[1]}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div className="mt-4 px-3">
        <div className="input-wrapper2 bg2 flex-item align-items-center">
          <div className="flex-label color-lights text-des">
            <FormattedMessage id="individualReward" />
          </div>
          <Input value={!ClaimableIncome[1] ? '0.0' : ClaimableIncome[1]} readOnly className="flex-content text-right no-border mx-2" />
          <Button danger className="warning linear linear-reverse mini" loading={state.loading2} onClick={() => withdraw(2)}>
            <FormattedMessage id="receive" />
          </Button>
        </div>
        <div className="input-wrapper2 bg2 mt-2 flex-item align-items-center">
          <div className="flex-label color-lights text-des">
            <FormattedMessage id="shareReward" />
          </div>
          <Input value={!ClaimableIncome[0] ? '0.0' : ClaimableIncome[0]} readOnly className="flex-content text-right no-border mx-2" />
          <Button danger className="warning linear linear-reverse mini" loading={state.loading1} onClick={() => withdraw(1)}>
            <FormattedMessage id="receive" />
          </Button>
        </div>
        <div className="input-wrapper2 bg2 mt-2 flex-item align-items-center">
          <div className="flex-label color-lights text-des">
            <FormattedMessage id="teamReward" />
          </div>
          <Input value={!ClaimableIncome[3] ? '0.0' : ClaimableIncome[3]} readOnly className="flex-content text-right no-border mx-2" />
          <Button danger className="warning linear linear-reverse mini" loading={state.loading4} onClick={() => withdraw(4)}>
            <FormattedMessage id="receive" />
          </Button>
        </div>
        <div className="input-wrapper2 bg2 mt-2 flex-item align-items-center">
          <div className="flex-label color-lights text-des">
            <FormattedMessage id="marketReward" />
          </div>
          <Input value={!ClaimableIncome[2] ? '0.0' : ClaimableIncome[2]} readOnly className="flex-content text-right no-border mx-2" />
          <Button danger className="warning linear linear-reverse mini" loading={state.loading3} onClick={() => withdraw(3)}>
            <FormattedMessage id="receive" />
          </Button>
        </div>
      </div>
      <div className="mt-5-5 text-zero">
        <img className="w-100" src={require('../../assets/img/foot2.png')} alt="" />
      </div>
    </div>
  );
}
