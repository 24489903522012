import './index.scss';
import myContext from '../../context';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Progress, Modal } from 'antd';
import { Carousel } from 'react-responsive-carousel';

export default function Index() {
  const navigate = useNavigate();
  const { locale } = useContext(myContext);

  const onGo = () => navigate('/go'); // 页面跳转
  const [notice, setNotice] = useState('这里是公告这里是公告这里是公告这里是公告这里是公告');
  const [modelVisible, setModelVisible] = useState(false); // 弹窗显示隐藏

  return (
    <div className="home-page">
      <Carousel autoPlay infiniteLoop interval="2000" renderArrowNext={() => false} renderArrowPrev={() => false} showStatus={false} showThumbs={false}>
        <div className="flex-center-center">
          <img className="w-100" src={require('../../assets/img/banner.png')} alt="" />
        </div>
        <div className="flex-center-center">
          <img className="w-100" src={require('../../assets/img/banner2.png')} alt="" />
        </div>
        <div className="flex-center-center">
          <img className="w-100" src={require('../../assets/img/banner0.png')} alt="" />
        </div>
      </Carousel>
      <div className="px-3 mt-4">
        <div onClick={() => setModelVisible(true)} className="notice-box cursor-pointer flex-item align-items-center bg-deep round-lg px-3-5">
          <img className="notice-icon flex-label" src={require('../../assets/img/i-sound.png')} alt="" />
          <div className="flex-content color-light text-overflow-ellipsis mx-2">{notice}</div>
          <img className="notice-icon flex-label" src={require('../../assets/img/i-arrow.png')} alt="" />
        </div>
        <div className="bg-deep round-lg p-3-5 mt-4">
          <div className="flex-item align-items-center">
            <div className="flex-content pr-5">
              <div className="shine-title">
                <FormattedMessage id="earn" />
              </div>
              <div className="color-light mt-2">
                <FormattedMessage id="global" />
              </div>
              <div className="mt-2 color-light">
                <span className="color-success">SHIB</span>
                <span>
                  <FormattedMessage id="wealth" />
                </span>
              </div>
            </div>
            <div className="flex-center-center">
              <img className="r-icon" src={require('../../assets/img/r2.png')} alt="" />
            </div>
          </div>
          <div className="d-flex mt-6 pt-5">
            <div className="common-button" onClick={onGo}>
              <FormattedMessage id="letGo" />
            </div>
          </div>
        </div>
        {/* <div className="bg-deep round-lg p-3-5 mt-4">
          <div className="flex-item align-items-center">
            <div className="flex-content pr-5">
              <div className="shine-title">
                <FormattedMessage id="intro" />
              </div>
              <div className="color-light mt-2">
                <FormattedMessage id="quickGet" />
              </div>
            </div>
            <div className="flex-label flex-center-center">
              <img className="r-icon" src={require('../../assets/img/r0.png')} alt="" />
            </div>
          </div>
          <div className="d-flex mt-6 pt-5">
            <div className="common-button" onClick={onGo}>
              <FormattedMessage id="letGo" />
            </div>
          </div>
        </div> */}

        <div className="bg-deep round-lg p-3-5 mt-4">
          <div className="flex-item align-items-center">
            <div className="flex-content pr-5">
              <div className="shine-title">
                <FormattedMessage id="oneIntro" />
              </div>
              <div className="color-light mt-2">
                <FormattedMessage id="explore" />
              </div>
              <div className="mt-2 color-light">
                <span className="color-success">
                  <FormattedMessage id="make" />
                </span>
                <span>
                  <FormattedMessage id="cyclic" />
                </span>
              </div>
            </div>
            <div className="flex-label flex-center-center">
              <img className="r-icon" src={require('../../assets/img/r1.png')} alt="" />
            </div>
          </div>
          <div className="d-flex mt-6 pt-5">
            <div className="common-button href-a">
              <a href="/staticPage/BONE.html">
                <FormattedMessage id="letGo" />
              </a>
            </div>
          </div>
        </div>

        <div className="bg-deep round-lg p-3-5 mt-4">
          <div className="flex-item align-items-center">
            <div className="flex-content pr-5">
              <div className="shine-title">
                <FormattedMessage id="intro" />
              </div>
              <div className="color-light mt-2">
                <FormattedMessage id="quickGet" />
              </div>
            </div>
            <div className="flex-label flex-center-center">
              <img className="r-icon" src={require('../../assets/img/r0.png')} alt="" />
            </div>
          </div>
          <div className="d-flex mt-6 pt-5">
            <div className="common-button href-a">
              <a href="/staticPage/SHIB.html">
                <FormattedMessage id="letGo" />
              </a>
            </div>
          </div>
        </div>

        <div className="bg-deep round-lg p-3-5 mt-4">
          <div className="flex-item align-items-center">
            <div className="flex-content pr-5">
              <div className="shine-title">
                <FormattedMessage id="community" />
              </div>
            </div>
            <div className="flex-label flex-center-center">
              <img className="r-icon" src={require('../../assets/img/r3.png')} alt="" />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <Progress percent={70} showInfo={false} strokeWidth="1.4rem" />
              <div className="color-light mt-1-5">
                <FormattedMessage id="share" />
                0/5亿shib
              </div>
            </div>
            <div className="mt-4">
              <Progress percent={70} showInfo={false} strokeWidth="1.4rem" />
              <div className="color-light mt-1-5">
                <FormattedMessage id="destroy" />
                0/9999亿
              </div>
            </div>
            <div className="mt-4">
              <Progress percent={70} showInfo={false} strokeWidth="1.4rem" />
              <div className="color-light mt-1-5">
                <FormattedMessage id="address" />
                0/5万
              </div>
            </div>
          </div>
        </div>
        <div className="bg-deep round-lg p-3-5 mt-4">
          <div className="flex-item align-items-center">
            <div className="flex-content pr-5">
              <div className="shine-title">
                <FormattedMessage id="ntf" />
              </div>
              <div className="color-light mt-2">
                <FormattedMessage id="only" />
              </div>
            </div>
            <div className="flex-label flex-center-center">
              <img className="r-icon" src={require('../../assets/img/r4.png')} alt="" />
            </div>
          </div>
          <div className="d-flex mt-6 pt-5">
            {/* <div className="common-button" onClick={onGo}> */}
            <div className="common-button" >
              <FormattedMessage id="letGo" />
            </div>
          </div>
        </div>
        <div className="bg-deep round-lg p-3-5 mt-4">
          <div className="flex-item align-items-center">
            <div className="flex-content pr-5">
              <div className="text-title color-light">
                <FormattedMessage id="coop" />
              </div>
            </div>
          </div>
          <div className="mt-4 pb-3">
            <div className="flex-item">
              <div className="flex-content flex-center-center mr-4-5">
                <img className="flex-label w-100" src={require('../../assets/img/c1.png')} alt="" />
              </div>
              <div className="flex-content flex-center-center">
                <img className="flex-label w-100" src={require('../../assets/img/c2.png')} alt="" />
              </div>
            </div>
            <div className="flex-item mt-3">
              <div className="flex-content flex-center-center mr-4-5">
                <img className="flex-label w-100" src={require('../../assets/img/c3.png')} alt="" />
              </div>
              <div className="flex-content flex-center-center">
                <img className="flex-label w-100" src={require('../../assets/img/c4.png')} alt="" />
              </div>
            </div>
            <div className="flex-item mt-3">
              <div className="flex-content flex-center-center mr-4-5">
                <img className="flex-label w-100" src={require('../../assets/img/c5.png')} alt="" />
              </div>
              <div className="flex-content flex-center-center">
                <img className="flex-label w-100" src={require('../../assets/img/c6.png')} alt="" />
              </div>
            </div>
            <div className="flex-item mt-3">
              <div className="flex-content flex-center-center mr-4-5">
                <img className="flex-label w-100" src={require('../../assets/img/c7.png')} alt="" />
              </div>
              <div className="flex-content flex-center-center">
                <img className="flex-label w-100" src={require('../../assets/img/c8.png')} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-center-center position-relative">
        <div className="absolute-center d-flex justify-content-center pb-5">
          <div className="flex-center-center cursor-pointer">
            <img className="b-icon" src={require('../../assets/img/b1.png')} alt="" />
          </div>
          <div className="flex-center-center cursor-pointer ml-3">
            <img className="b-icon" src={require('../../assets/img/b2.png')} alt="" />
          </div>
          <div className="flex-center-center cursor-pointer ml-3">
            <img className="b-icon" src={require('../../assets/img/b3.png')} alt="" />
          </div>
          <div className="flex-center-center cursor-pointer ml-3">
            <img className="b-icon" src={require('../../assets/img/b4.png')} alt="" />
          </div>
        </div>
        <img className="w-100" src={require('../../assets/img/foot.png')} alt="" />
      </div>

      <Modal title="公告详情" centered footer={false} width="22rem" open={modelVisible} onCancel={() => setModelVisible(false)}>
        <p>{notice}</p>
      </Modal>
    </div>
  );
}
