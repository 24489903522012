import Home from '../views/Home';
import Go from '../views/Go';
//import Show from '../views/Show';
import Manage from '../views/Manage';

const routes = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/go',
    element: <Go />
  },
  // {
  //   path: '/show',
  //   element: <Show />
  // },
  {
    path: '/manage',
    element: <Manage />
  }
];

export default routes;
