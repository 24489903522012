import myContext from '../../context';
import { ethers } from 'ethers';
import { Carousel } from 'react-responsive-carousel';
import { splitArr } from '../../hooks/splitArr';
import GameABI from '../../hardhat/artifacts/contracts/game.sol/Game.json';
import TokenABI from '../../hardhat/artifacts/contracts/game.sol/IERC20.json';
import {  Button, Input,message } from 'antd';
import { useEffect, useState, useContext } from 'react';

let GameAddrContract;
let OneContract;
let ShibContract;

let arr= {
  
}
let arrs = [];
export default function Index() {
  const { WalletProviders ,GameAddr,One,Shib} = useContext(myContext);
  const [BaseInfo, setBaseInfo] = useState(['','','','']);
  const [BaseLoading, setBaseLoading] = useState(false);
  //const [arrss, setArrss] = useState();
  const [query0, setQuery0] = useState();
  const [query1, setQuery1] = useState();
  const [query2, setQuery2] = useState();
  const [query3, setQuery3] = useState();
  const [query4, setQuery4] = useState();
  const [query5, setQuery5] = useState();
  const [query6, setQuery6] = useState(['','']);
  const [query7, setQuery7] = useState();
  
  
  const [V0, setV0] = useState();
  const [V1, setV1] = useState();
  const [V2, setV2] = useState();
  const [V3, setV3] = useState();
  const [V4, setV4] = useState();
  const [V5, setV5] = useState();
  const [V6, setV6] = useState();
  const [V7, setV7] = useState();

  const [Z0, setZ0] = useState();
  const [Z1, setZ1] = useState();
  const [Z2, setZ2] = useState();
  const [Z3, setZ3] = useState();
  const [Z4, setZ4] = useState();
  const [Z5, setZ5] = useState();
  const [Z6, setZ6] = useState();
  const [Z7, setZ7] = useState();
  const [Z8, setZ8] = useState();

  useEffect(() => {
    if (!WalletProviders) {
      return;
    }
    
  }, [WalletProviders]);

  let Processings = (x) => ethers.utils.formatUnits(x.toString().match(/^\d+(?:\.\d{0,2})?/).toString(), 18);

  let GetInfo = async () => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      setBaseLoading(true);
      OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      //最大节点数
      let queryNumberOfUsersMax = await GameAddrContract.queryNumberOfUsersMax();
      //已成为节点账户的人数
      let queryNumberOfUsers = await GameAddrContract.queryNumberOfUsers();
      //查看静态收益率
      let queryStaticRewardsFee = await GameAddrContract.queryStaticRewardsFee();
      //查看节点地址列表
      let queryNodeList = await GameAddrContract.queryNodeList();
      arrs =[];
      for (let i = 0; i < queryNodeList.length; i++) {
        let obj = new Object()
        obj.address = queryNodeList[i].toString();
        obj.value = queryNodeList.length - i;
        arrs.push(obj);
      }
      // let obj = new Object()
      // obj.address = '0x0';
      // obj.value = '666';
      // arrs.push(obj);
      
      setBaseInfo([queryNumberOfUsersMax.toString(),queryNumberOfUsers.toString(),queryStaticRewardsFee.toString(),queryNodeList]);
      //console.log(queryNumberOfUsersMax);
      setBaseLoading(false);
    } catch (err) {
      setBaseLoading(false);
      console.log(err);
    }
  };

  //指定地址是否为节点地址
  let Query0 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //指定地址是否为节点地址
      let queryIsNodeAddr = await GameAddrContract.queryIsNodeAddr(_addr);
      setQuery0(queryIsNodeAddr.toString());

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //查看指定地址直接推荐人数
  let Query1 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //查看指定地址直接推荐人数
      let RecommendedNumberSum = await GameAddrContract.RecommendedNumberSum(_addr);
      setQuery1(RecommendedNumberSum.toString());

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //查看指定地址推荐人列表以及每个直推地址的伞下业绩
  let Query2 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //查看指定地址推荐人列表
      let RecommendedSum = await GameAddrContract.RecommendedSum(_addr);
      arrs =[];
      for (let i = 0; i < RecommendedSum.length; i++) {
        let value_ = await GameAddrContract.RecommendedAchievementSum(RecommendedSum[i]);
        arr[RecommendedSum[i].toString().substring(0, 6) + '...' + RecommendedSum[i].toString().substring(35, 42)] = Processings(value_.toString());
        let obj = new Object()
        obj.address = RecommendedSum[i].toString();
        obj.value = Processings(value_.toString())
        //arrs.push(arr[RecommendedSum[i].toString()] = Processings(value_.toString()));
        arrs.push(obj);
      }
      // let obj = new Object()
      // obj.address = '0x0';
      // obj.value = '666';
      // arrs.push(obj);
      //console.log('arrs',arrs);
      //setArrss(arrs);
      setQuery2(arr);

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };

  const tableToExcel = (_name) => {
        // 列标题，逗号隔开，每一个逗号就是隔开一个单元格
        //console.log('999',arrs);
        let str = `地址,业绩\n`;
        // 增加\t为了不让表格显示科学计数法或者其他格式
        for(let i = 0 ; i < arrs.length ; i++ ){
            for(const key in arrs[i]){
                str+=`${arrs[i][key]},`;     
            }
            str+='\n';
        }
        // encodeURIComponent解决中文乱码
        const uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
        // 通过创建a标签实现
        const link = document.createElement("a");
        link.href = uri;
        // 对下载的文件命名
        link.download =  _name+".csv";
        link.click();
  }
  const tableToExcel1 = (_name) => {
    // 列标题，逗号隔开，每一个逗号就是隔开一个单元格
    //console.log('999',arrs);
    let str = `地址\n`;
    // 增加\t为了不让表格显示科学计数法或者其他格式
    for(let i = 0 ; i < arrs.length ; i++ ){
        for(const key in arrs[i]){
            str+=`${arrs[i][key] },`;     
        }
        str+='\n';
    }
    // encodeURIComponent解决中文乱码
    const uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
    // 通过创建a标签实现
    const link = document.createElement("a");
    link.href = uri;
    // 对下载的文件命名
    link.download =  _name+".csv";
    link.click();
}
  //指定地址的总业绩
  let Query3 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //指定地址的总业绩
      let RecommendedAchievementSum = await GameAddrContract.RecommendedAchievementSum(_addr);
      setQuery3(Processings(RecommendedAchievementSum.toString()));

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //查看指定地址的上级
  let Query4 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //查看指定地址的上级
      let querySuperior = await GameAddrContract.querySuperior(_addr);
      setQuery4(querySuperior.toString());

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //查看指定地址团队等级
  let Query5 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //查看指定地址团队等级
      let queryTeamLevel = await GameAddrContract.queryTeamLevel(_addr);
      setQuery5(queryTeamLevel.toString());

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //查看指定地址购买金额以及预估最大可领收益
  let Query6 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //查看指定地址购买金额以及预估最大可领收益
      let buyAmount = await GameAddrContract.buyAmount(_addr);
      setQuery6([Processings(buyAmount[0].toString()),Processings(buyAmount[1].toString())]);

      console.log(buyAmount);
    } catch (err) {
      console.log(err);
    }
  };
  //查看指定地址已提现金额
  let Query7 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //查看指定地址已提现金额
      let withdrawalAmount = await GameAddrContract.withdrawalAmount(_addr);
      setQuery7(Processings(withdrawalAmount.toString()));

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };

  //设置帕点地址以及利率
  let Set0 = async (_addr,_fee) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //设置帕点地址以及利率
      let tx = await GameAddrContract.setPascalPoint(_addr,_fee);
      await tx.wait();
      

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //设置迭代层数
  let Set1 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //设置迭代层数
      let tx = await GameAddrContract.setHierarchy(_addr);
      await tx.wait();
      

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //设置成为节点账户的门槛
  let Set2 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //设置成为节点账户的门槛
      let tx = await GameAddrContract.setThreshold(_addr);
      await tx.wait();
      

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //设置节点账户人数上限
  let Set3 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //设置节点账户人数上限
      let tx = await GameAddrContract.setNumberOfUsersMax(_addr);
      await tx.wait();
      

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //设置静态收益率
  let Set4 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //设置静态收益率
      let tx = await GameAddrContract.setYield(_addr);
      await tx.wait();
      

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //设置最小投入
  let Set5 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //设置最小投入
      let tx = await GameAddrContract.setMinimumInput(_addr);
      await tx.wait();
      

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //设置提现时节点钱包地址
  let Set6 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //设置提现时节点钱包地址
      let tx = await GameAddrContract.setNodeAddr(_addr);
      await tx.wait();
      

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };
  //设置直推2级收益率
  let Set7 = async (_addr,_fee) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //设置直推2级收益率
      let tx = await GameAddrContract.setOneDirectRefAndTwoDirectRef(_addr,_fee);
      await tx.wait();
      

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };

  // 设置黑名单
  let Set8 = async (_addr) => {
    try {
      if (!WalletProviders) {
        message.warning('请先连接钱包!');
        return;
      }
      if(!_addr){
        return
      }
      if(!OneContract){
        OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!ShibContract){
        ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      }
      if(!GameAddrContract){
        GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      }
      
      
      //设置黑名单
      let tx = await GameAddrContract.setBlack(_addr);
      await tx.wait();

      //console.log(queryNumberOfUsersMax);
    } catch (err) {
      console.log(err);
    }
  };


  return (
  <div className="manage-page px-3 pb-3">
    <Button className="w-100 mb-3" loading={BaseLoading} onClick={async ()=>{
      await GetInfo();
    }}>读取数据</Button>
    <div>
      <div>----------------数据展示---------------</div>
      <div>最大节点数:{BaseInfo[0]}</div>
      <div>已成为节点账户的人数:{BaseInfo[1]}</div>
      <Button className="w-100 mb-3" onClick={async()=>{
        tableToExcel1('节点账户列表');
      }}>下载excel</Button>
      <div>查看静态收益率:{BaseInfo[2]}%</div>
      
      <div>指定地址是否为节点地址:{query0}</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setV0(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Query0(V0);
      }}>查询</Button>

      <div>查看指定地址直接推荐人数:{query1}</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setV1(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Query1(V1);
      }}>查询</Button>

      <div>查看指定地址推荐人列表以及每个直推地址的伞下业绩:</div>
      <Carousel autoPlay infiniteLoop interval="100000" showIndicators={false} showStatus={false} showThumbs={false}>
              {query2?splitArr(Object.entries(query2), 3).map((item, index) => {
                //console.log(query2);
                return (
                  <div key={index} className="px-0-5">
                    {item.map((subItem, subIndex) => {
                      return (
                        <div key={subIndex} className={`color-light flex-item ${subIndex ? 'mt-2' : ''}`}>
                          <div className="flex-content text-des text-left">{subItem[0]}</div>
                          <div className="flex-label color-white">{subItem[1]}</div>
                        </div>
                      );
                    })}
                  </div>
                );
              }):''}
            </Carousel>
      
      <Input  className="mb-2"
      onChange={async (event) => {
        setV2(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Query2(V2);
      }}>查询</Button>
      <Button className="w-100 mb-3" onClick={async()=>{
        tableToExcel('地址推荐人列表以及每个直推地址的伞下业绩');
      }}>下载excel</Button>
      <div>指定地址的总业绩:{query3}</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setV3(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Query3(V3);
      }}>查询</Button>

      <div>查看指定地址的上级:{query4}</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setV4(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Query4(V4);
      }}>查询</Button>

      <div>查看指定地址团队等级:{query5}</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setV5(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Query5(V5);
      }}>查询</Button>

      <div>查看指定地址购买金额以及预估最大可领收益</div>
      <div>购买金额:{query6[0]}</div>
      <div>预估最大可领收益:{query6[1]}</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setV6(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Query6(V6);
      }}>查询</Button>

      <div>查看指定地址已提现金额:{query7}</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setV7(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Query7(V7);
      }}>查询</Button>
      


      
      <div>----------------交互区-----------------</div>
      
      <div>设置帕点地址以及利率</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setZ0(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Set0(Z0);
      }}>写入</Button>
      <div>设置迭代层数</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setZ1(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Set1(Z1);
      }}>写入</Button>
      <div>设置成为节点账户的门槛</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setZ2(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Set2(Z2);
      }}>写入</Button>
      <div>设置节点账户人数上限</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setZ3(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Set3(Z3);
      }}>写入</Button>
      <div>设置静态收益率</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setZ4(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Set4(Z4);
      }}>写入</Button>
      <div>设置最小投入</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setZ5(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Set5(Z5);
      }}>写入</Button>
      <div>设置提现时节点钱包地址</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setZ6(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Set6(Z6);
      }}>写入</Button>
      <div>设置直推2级收益率</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setZ7(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Set7(Z7);
      }}>写入</Button>
      <div> 设置无收益黑名单</div>
      <Input  className="mb-2"
      onChange={async (event) => {
        setZ8(event.target.value);
              }}/>
      <Button className="w-100 mb-3" onClick={async ()=>{
        await Set8(Z8);
      }}>写入</Button>
      
      
      
      
      
      </div>  

  </div>
  );
}
