const zh = {
  linkWattet: '连接钱包',
  intro: 'SHIB介绍',
  quickGet: '快速了解SHIB未来价值',
  letGo: "let's go",
  oneIntro: 'SHIB-BONE介绍',
  explore: '让我们一起探索SHIB生态，',
  make: '打造',
  cyclic: 'SHIB生态循环',
  earn: '赚SHIB',
  global: '全球唯一官方SHIB生态社区，让我们一起赚更多的SHIB，',
  wealth: '将是你不能错过的财富',
  community: '社区权益',
  share: '节点分红：',
  destroy: '销毁数量：',
  address: '持币地址：',
  ntf: 'NTF卡牌',
  only: 'SHIB生态2.0将产生唯一的，限量的，仅有的nft卡牌，未来价值不可估量，值的你收藏拥有。',
  coop: '合作机构',
  invest: '投入',
  superiorAddress: '上级地址:',
  copy: '复制',
  bindingParent: '绑定上级:',
  bind: '绑定',
  myShareLink: '我的分享链接:',
  myInvitationCode: '我的邀请码:',
  dailyReturnRate: '日收益率',
  walletBalance: '钱包余额',
  numOfParticipants: '参与数量',
  participateRow: '*一次性参与2亿以上SHIB升级为节点*',
  confirm: '确定',
  boneBalance: 'BONE余额',
  amplificationIncome: '放大收益',
  acceleratedRelease: '加速释放',
  toBeReleased: '待释放量',
  teamLevel: '团队等级',
  isNode: '是否节点',
  yes: '是',
  no: '否',
  sharePersonNum: '分享人数',
  sharePerformance: '分享业绩',
  teamPerformance: '团队业绩',
  directAddress: '直推地址',
  individualReward: '个人奖励',
  shareReward: '分享奖励',
  teamReward: '团队奖励',
  marketReward: '市场奖励',
  receive: '领取'
};

export default zh;
