const en = {
  linkWattet: 'Link Wattet',
  intro: 'Introduction of shib',
  quickGet: "Learn about shib's future value quickly",
  letGo: "let's go",
  oneIntro: 'Introduction of SHIB-BONE',
  explore: "Let's explore shib ecology,",
  make: 'Create',
  cyclic: 'shib ecological cycle',
  earn: 'Earn shib',
  global: "The world's only official shib ecological community, let's earn more Shibs together,",
  wealth: "Will be a treasure you can't miss",
  community: 'Community rights and interests',
  share: 'Node dividend:',
  destroy: 'Quantity destroyed:',
  address: 'Holding address:',
  ntf: 'NTF card',
  only: 'shib Eco 2.0 will produce unique, limited, and only nft cards that will be invaluable to your collection in the future.',
  coop: 'Cooperative agency',
  invest: 'invest',
  superiorAddress: 'Superior address:',
  copy: 'Copy',
  bindingParent: 'Binding parent:',
  bind: 'Bind',
  myShareLink: 'My share link:',
  myInvitationCode: 'My invitation code:',
  dailyReturnRate: 'daily return rate',
  walletBalance: 'Wallet balance',
  numOfParticipants: 'Number of participants',
  participateRow: '*Participate in the upgrading of more than 200 million SHIBs to nodes at a time*',
  confirm: 'Confirm',
  boneBalance: 'BONE balance',
  amplificationIncome: 'Amplification of income',
  acceleratedRelease: 'Accelerated release',
  toBeReleased: 'Quantity to be released',
  teamLevel: 'Team level',
  isNode: 'Is node',
  yes: 'Yes',
  no: 'No',
  sharePersonNum: 'Number of people sharing',
  sharePerformance: 'Share performance',
  teamPerformance: 'Team performance',
  directAddress: 'Direct push address',
  individualReward: 'Individual reward',
  shareReward: 'Share rewards',
  teamReward: 'Team reward',
  marketReward: 'Market reward',
  receive: 'receive'
};

export default en;
